import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index'
import * as echarts from 'echarts'
import VueGridLayout from 'vue-grid-layout'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './utils/main.css'
import loading from '@/components/loading.vue'
const app = createApp(App)
app.component('loading',loading)

const wssLink = `wss://api.dabhkwurotbajcdsmebal.com/ws`
const testChang = (value) => {
	if(localStorage.getItem('configInfo')){
		return value.replaceAll('aaaa',JSON.parse(localStorage.getItem('configInfo')).site_name)
	}else{
		return null
	}
}

const testChang2 = (value) => {
	if(localStorage.getItem('configInfo')){
		return value.replaceAll('Bitop',JSON.parse(localStorage.getItem('configInfo')).site_name)
	}else{
		return null
	}
}

const testChang3 = (value) => {
	if(localStorage.getItem('configInfo')){
		return value.replaceAll('Bittop',JSON.parse(localStorage.getItem('configInfo')).site_name)
	}else{
		return null
	}
}

const testChang4 = (value) => {
	if(localStorage.getItem('configInfo')){
		return value.replaceAll('tihuantitle',JSON.parse(localStorage.getItem('configInfo')).site_name)
	}else{
		return null
	}
}



app.config.globalProperties.$testChang = testChang
app.config.globalProperties.$testChang2 = testChang2
app.config.globalProperties.$testChang3 = testChang3
app.config.globalProperties.$testChang4 = testChang4
app.config.globalProperties.$wssLink = wssLink

app.config.globalProperties.$echarts = echarts
app.use(i18n).use(store).use(router).use(VueGridLayout).use(Antd).mount('#app')
